@import '../../assets/styles/_all';

.alert{
  background:$b4s-pink-8;
  border: 1px solid $b4s-pink-6;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  @include b4s-body-small($b4s-blue-2, 500);
  ul {
    margin-left: 20px;
  }
  &.success{
    background:$b4s-green-1;
    border: 1px solid $b4s-green-2;
  }
}